import { render, staticRenderFns } from "./ViewDictionary.vue?vue&type=template&id=aeb3cec2&scoped=true&"
import script from "./ViewDictionary.vue?vue&type=script&lang=ts&"
export * from "./ViewDictionary.vue?vue&type=script&lang=ts&"
import style0 from "./ViewDictionary.vue?vue&type=style&index=0&id=aeb3cec2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aeb3cec2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAutocomplete,VCol,VContainer,VRow,VSkeletonLoader})
