









































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Location } from 'vue-router'
import { auth } from '@/api/firebase'
import { Dictionary } from '@/api/dictionary'

interface MenuItem {
  label: string,
  icon: string,
  to: () => string | Location
}

@Component({})
export default class DictionaryNavigation extends Vue {
  @Prop({ required: true }) readonly dictionary!: Dictionary | null

  private items: MenuItem[] = []

  get initials (): string | undefined {
    if(this.dictionary?.name == undefined) {
      return undefined
    }

    const name = this.dictionary?.name
    const parts = name.split(' ')

    return parts.length > 1
      ? (parts[0][0] + parts[1][0]).toUpperCase()
      : name.slice(0, 2).toUpperCase()
  }

  @Watch('dictionary')
  watchDictionary(): void {
    if(this.dictionary) {
      const d = this.dictionary
      this.items = auth.currentUser && d.members.includes(auth.currentUser.uid)
        ? [
          { label: 'Dictionary', icon: 'mdi-book-open-variant', to: () => ({ name: 'view-dictionary', params: { id: d.id } }) },
          { label: 'Gloss', icon: 'mdi-transcribe', to: () => ({ name: 'gloss-dictionary', params: { id: d.id } }) },
          { label: 'Rules', icon: 'mdi-file-tree', to: () => ({ name: 'rule-dictionary', params: { id: d.id } }) },
          { label: 'Settings', icon: 'mdi-settings', to: () => ({ name: 'setup-dictionary', params: { id: d.id } }) }
        ] : [
          { label: 'Dictionary', icon: 'mdi-book-open-variant', to: () => ({ name: 'view-dictionary', params: { id: d.id } }) },
          { label: 'Gloss', icon: 'mdi-transcribe', to: () => ({ name: 'gloss-dictionary', params: { id: d.id } }) }
        ]
    } else {
      this.items = []
    }
  }
}
