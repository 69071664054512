




































import { Component, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'
import setTitle from '@/api/title'
import { Dictionary } from '@/api/dictionary'
import { getDictionary } from '@/api/dictionaries'
import DictionaryNavigation from '@/components/DictionaryNavigation.vue'
import Definition from '@/components/Definition.vue'

@Component({
  components: {
    DictionaryNavigation,
    Definition
  }
})
export default class ViewDictionary extends Vue {
  private dictionary: Dictionary | null = null

  created (): void {
    getDictionary(this.$route.params.id)?.onSnapshot(d => {
      const dictionary = d.data()
      this.dictionary = dictionary ?? new Dictionary(this.$route.params.id, "Unknown", [], "", dayjs(), 0)
      if (dictionary) {
        setTitle(dictionary.name)
      }
    })
  }
}
