














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({ })
export default class Definition extends Vue {
  @Prop(String) name?: string
  @Prop(String) description?: string
  @Prop() definitions?: string[]
}
